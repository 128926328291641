/**
 *
 * OrderUploadModal
 *
 */

import { Text } from 'components/Text'
import {
  selectIsCodSupported,
  selectIsNinjaPackOCSupported,
  selectIsCorporateAWBOCSupported,
  selectIsParcelOCSupported,
  selectIsB2BBundleSupported,
  selectIsCorporateB2BBundleSupported,
  selectIsAllowedToUseAccountBalance
} from 'containers/OrderCreate/selectors'
import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import styled, { css } from 'styled-components'
import _ from 'lodash'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { Dropdown, Icon, Menu, T } from '@nv/react-commons/src/Components'
import { CsvUtils, ExcelUtils, FileUtils } from '@nv/react-commons/src/Utils'
import { Button } from 'components/Button'
import { CenterText } from 'components/CenterText'
import { CircleIcon } from 'components/CircleIcon'
import { Dropzone } from 'components/Dropzone'
import { Link } from 'components/Link'
import { RouteModal } from 'components/RouteModal'
import { Vspace } from 'components/Vspace'
import { Colors } from 'themes'
import csvMapping from 'utils/csvMapping'
import { orderCreateCreators } from '../Base/redux'
import {
  selectAllowedDeliveryTimeslots,
  selectIsEnabledTemperatureRanges,
  selectShipper,
  selectRecipientType
} from '../Base/selectors'

import { faArrowToBottom } from '@fa-pro-light/faArrowToBottom'
import { faCloudUpload } from '@fa-pro-light/faCloudUpload'
import { faKeyboard } from '@fa-pro-light/faKeyboard'
import { OC_SERVICE_TYPES, ROUTES } from 'containers/Base/constants'
import { withNavigate, withLocation } from '../RouterHOCs'
import { mixpanelTrackUploadBulkOrderData } from 'components/Mixpanel/helpers'
import { RecipientType } from 'containers/OrderTypeModal/constants'

const floatRight = css`
  float: right;
`
const centered = css`
  display: block;
  margin: 0 auto;
`
const StyledButton = styled(({ isFloatRight: _isFloatRight, ...rest }) => <Button {...rest} />)`
  &&.ant-btn {
    ${props => (props.isFloatRight ? floatRight : centered)};
  }
`
const StyledLink = styled(({ hide: _hide, ...props }) => <Link {...props} />)`
  && {
    color: ${Colors.pinkishGrey};
    font-size: 13px;
    ${props => props.hide && 'visibility: hidden;'};
  }
`
const StyledIcon = styled(Icon)`
  && {
    margin-right: 8px;
    color: inherit;
  }
`
const StyledCenterText = styled(CenterText)`
  color: ${Colors.warmGrey};
`
const TextCenteredDiv = styled.div`
  text-align: center;
`
const StyledText = styled(Text)`
  display: inline-block;
`

export class OrderUploadModal extends React.Component {
  onUploadFile = async file => {
    const { navigate, location, save } = this.props
    const filename = file?.name
    let results
    if (_.endsWith(filename, '.csv')) {
      results = await CsvUtils.parse(file)
      save('upload', {
        filename: file.name,
        data: results.data
      })
    } else {
      results = await ExcelUtils.parseFile(file)
      save('upload', {
        filename: file.name,
        data: results
      })
    }

    mixpanelTrackUploadBulkOrderData()

    const to = location?.state?.to
    if (to) {
      const { to: _ignoredTo, ...state } = location.state
      navigate(to, { state })
    } else {
      navigate('./', { state: location.state })
    }
  }

  renderDomesticMenuItems = () => {
    const {
      intl,
      shipper,
      isCodSupported,
      isAllowedToUseAccountBalance,
      isB2BBundleSupported,
      allowedDeliveryTimeslots,
      isEnabledTemperatureRanges
    } = this.props

    const domFields = csvMapping.getOrderFields({
      serviceType: OC_SERVICE_TYPES.PARCEL,
      shipper,
      isCodSupported,
      isAllowedToUseAccountBalance,
      allowedDeliveryTimeslots,
      isEnabledTemperatureRanges
    })
    const domLabelToKey = csvMapping.makeLabelToDataMap(domFields)
    const sampleType = intl.formatMessage({ id: 'types.mapping.parcel' })
    return [
      <Menu.Item key='csv'>
        <a
          testid='download-domestic-sample'
          onClick={() =>
            FileUtils.download(
              FileUtils.buildCSVURI({
                fields: _.keys(domLabelToKey),
                data: _.values(domLabelToKey)
              }),
              { filename: 'sample.csv' }
            )
          }
        >
          <T id={isB2BBundleSupported ? 'b2c_csv' : 'download_x_csv_sample'} values={{ x: sampleType }} />
        </a>
      </Menu.Item>,
      <Menu.Item key='xlsx'>
        <a
          testid='download-domestic-excel-sample'
          onClick={() =>
            ExcelUtils.downloadFileWithHeaders(
              [_.values(domLabelToKey)],
              [_.keys(domLabelToKey)],
              'sample_domestic.xlsx'
            )
          }
        >
          <T id={isB2BBundleSupported ? 'b2c_excel' : 'download_x_excel_sample'} values={{ x: sampleType }} />
        </a>
      </Menu.Item>
    ]
  }

  renderMpsMenuItems = () => {
    const {
      shipper,
      isCodSupported,
      isAllowedToUseAccountBalance,
      isB2BBundleSupported,
      allowedDeliveryTimeslots,
      isEnabledTemperatureRanges
    } = this.props

    const domFields = csvMapping.getOrderFields({
      serviceType: OC_SERVICE_TYPES.B2B_BUNDLE,
      shipper,
      isCodSupported,
      isAllowedToUseAccountBalance,
      isB2BBundleSupported,
      allowedDeliveryTimeslots,
      isEnabledTemperatureRanges
    })
    const domLabelToKey = csvMapping.makeLabelToDataMap(domFields)

    return [
      <Menu.Item key='csv'>
        <a
          testid='download-mps-sample'
          onClick={() =>
            FileUtils.download(
              FileUtils.buildCSVURI({
                fields: _.keys(domLabelToKey),
                data: _.values(domLabelToKey)
              }),
              { filename: 'multi_piece_shipment_sample.csv' }
            )
          }
        >
          <T id='b2b_csv' />
        </a>
      </Menu.Item>,
      <Menu.Item key='xlsx'>
        <a
          testid='download-mps-excel-sample'
          onClick={() =>
            ExcelUtils.downloadFileWithHeaders(
              [_.values(domLabelToKey)],
              [_.keys(domLabelToKey)],
              'multi_piece_shipment_sample.xlsx'
            )
          }
        >
          <T id='b2b_excel' />
        </a>
      </Menu.Item>
    ]
  }

  renderNinjaPackMenuItems = () => {
    const { intl, shipper, isCodSupported, allowedDeliveryTimeslots } = this.props
    const ninjaPackFields = csvMapping.getOrderFields({
      serviceType: OC_SERVICE_TYPES.NINJA_PACKS,
      shipper,
      isCodSupported,
      allowedDeliveryTimeslots
    })
    const npLabelToKey = csvMapping.makeLabelToDataMap(ninjaPackFields)
    const sampleType = intl.formatMessage({ id: 'types.mapping.ninja_pack' })
    return [
      <Menu.Item key='ninja-pack'>
        <a
          testid='download-ninja-pack-sample'
          onClick={() =>
            FileUtils.download(
              FileUtils.buildCSVURI({
                fields: _.keys(npLabelToKey),
                data: _.values(npLabelToKey)
              }),
              { filename: 'ninja_pack_sample.csv' }
            )
          }
        >
          <T id='download_x_csv_sample' values={{ x: sampleType }} />
        </a>
      </Menu.Item>,
      <Menu.Item key='ninja-pack-xlsx'>
        <a
          testid='download-ninja-pack-excel-sample'
          onClick={() =>
            ExcelUtils.downloadFileWithHeaders(
              [_.values(npLabelToKey)],
              [_.keys(npLabelToKey)],
              'ninja_pack_sample.xlsx'
            )
          }
        >
          <T id='download_x_excel_sample' values={{ x: sampleType }} />
        </a>
      </Menu.Item>
    ]
  }

  renderCorpAwbMenuItems = () => {
    const { intl, shipper, allowedDeliveryTimeslots } = this.props
    const corpAwbFields = csvMapping.getOrderFields({
      serviceType: OC_SERVICE_TYPES.CORPORATE_AWB,
      shipper,
      allowedDeliveryTimeslots
    })
    const npLabelToKey = csvMapping.makeLabelToDataMap(corpAwbFields)
    const sampleType = intl.formatMessage({ id: 'types.mapping.corporate_awb' })
    return [
      <Menu.Item key='corporate-awb'>
        <a
          testid='download-corporate-awb-sample'
          onClick={() =>
            FileUtils.download(
              FileUtils.buildCSVURI({
                fields: _.keys(npLabelToKey),
                data: _.values(npLabelToKey)
              }),
              { filename: 'corporate_awb_sample.csv' }
            )
          }
        >
          <T id='download_x_csv_sample' values={{ x: sampleType }} />
        </a>
      </Menu.Item>,
      <Menu.Item key='corporate-awb-xlsx'>
        <a
          testid='download-corporate-awb-excel-sample'
          onClick={() =>
            ExcelUtils.downloadFileWithHeaders(
              [_.values(npLabelToKey)],
              [_.keys(npLabelToKey)],
              'corporate_awb_sample.xlsx'
            )
          }
        >
          <T id='download_x_excel_sample' values={{ x: sampleType }} />
        </a>
      </Menu.Item>
    ]
  }

  renderMenu = () => {
    const {
      isRegularOC,
      isNinjaPackOC,
      isCorporateAWBOC,
      isParcelOCSupported,
      isNinjaPackOCSupported,
      isCorporateAWBOCSupported,
      isB2BBundleSupported,
      recipientType
    } = this.props

    let menuItems = []
    if (isRegularOC) {
      menuItems = _.compact([
        (!recipientType || recipientType === RecipientType.B2B) && isB2BBundleSupported && this.renderMpsMenuItems(),
        (!recipientType || recipientType === RecipientType.B2C) && isParcelOCSupported && this.renderDomesticMenuItems()
      ])
    } else if (isNinjaPackOC) {
      menuItems = _.compact([isNinjaPackOCSupported && this.renderNinjaPackMenuItems()])
    } else if (isCorporateAWBOC) {
      menuItems = _.compact([isCorporateAWBOCSupported && this.renderCorpAwbMenuItems()])
    } else {
      menuItems = _.compact([
        isParcelOCSupported && this.renderDomesticMenuItems(),
        isNinjaPackOCSupported && this.renderNinjaPackMenuItems(),
        isCorporateAWBOCSupported && this.renderCorpAwbMenuItems()
      ])
    }

    return <Menu>{menuItems}</Menu>
  }

  /* eslint-disable react/jsx-handler-names */
  renderContent = () => {
    const { clearOrders, hideSwitchToManual } = this.props
    return (
      <>
        <Dropzone
          data-analyticsid='browseFilesForBulkOC'
          height={210}
          onDrop={this.onUploadFile}
          hint={
            <>
              <CircleIcon icon={faCloudUpload} size='big' type='success' gap={1} />
              <Vspace height={16} />
              <StyledCenterText size={16} id='upload_csv_xls' type='bold' />
            </>
          }
        />
        <Vspace height={16} />
        <TextCenteredDiv>
          <StyledText id='need_help_getting_started' /> <StyledText type='bold' id='download' />{' '}
          <StyledText id='the_template_below' />
        </TextCenteredDiv>
        <Vspace height={12} />
        <Dropdown overlay={this.renderMenu()} placement='bottomRight' trigger={['click']}>
          <StyledButton
            icon={faArrowToBottom}
            rightIcon='dropdown'
            data-analyticsid='downloadCSVMappingTemplate'
            isFloatRight={!hideSwitchToManual}
          >
            <T id='download_template' />
          </StyledButton>
        </Dropdown>
        {!hideSwitchToManual && (
          <StyledLink to={ROUTES.OC_MANUAL} onClick={clearOrders} data-analyticsid='switchToManualOC'>
            <StyledIcon icon={faKeyboard} />
            <T id='switch_to_keyboard' />
          </StyledLink>
        )}
      </>
    )
  }

  render() {
    return (
      <RouteModal path={this.props.path} title={<T id='upload_order_data' />}>
        {this.renderContent()}
      </RouteModal>
    )
  }
}

OrderUploadModal.propTypes = {
  path: PropTypes.string,
  navigate: PropTypes.func,
  location: PropTypes.object,
  intl: PropTypes.object,
  hideSwitchToManual: PropTypes.bool,
  shipper: PropTypes.object.isRequired,
  isParcelOCSupported: PropTypes.bool,
  isNinjaPackOCSupported: PropTypes.bool,
  isRegularOC: PropTypes.bool,
  isNinjaPackOC: PropTypes.bool,
  isAllowedToUseAccountBalance: PropTypes.bool,
  save: PropTypes.func.isRequired,
  clearOrders: PropTypes.func.isRequired,
  isCodSupported: PropTypes.bool,
  isCorporateAWBOCSupported: PropTypes.bool,
  isCorporateAWBOC: PropTypes.bool,
  allowedDeliveryTimeslots: PropTypes.array,
  isEnabledTemperatureRanges: PropTypes.bool
}

OrderUploadModal.defaultProps = {
  isRegularOC: false,
  isNinjaPackOC: false,
  isCorporateAWBOC: false,
  isEnabledTemperatureRanges: false
}

const mapStateToProps = createStructuredSelector({
  shipper: selectShipper(),
  isParcelOCSupported: selectIsParcelOCSupported(),
  isNinjaPackOCSupported: selectIsNinjaPackOCSupported(),
  isCorporateAWBOCSupported: selectIsCorporateAWBOCSupported(),
  isCodSupported: selectIsCodSupported(),
  isAllowedToUseAccountBalance: selectIsAllowedToUseAccountBalance(),
  isCorporateB2BBundleSupported: selectIsCorporateB2BBundleSupported(),
  isB2BBundleSupported: selectIsB2BBundleSupported(),
  allowedDeliveryTimeslots: selectAllowedDeliveryTimeslots(),
  isEnabledTemperatureRanges: selectIsEnabledTemperatureRanges(),
  recipientType: selectRecipientType()
})

function mapDispatchToProps(dispatch) {
  return {
    save: (key, data) => dispatch(orderCreateCreators.set(key, data)),
    clearOrders: () => dispatch(orderCreateCreators.clearOrders())
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withLocation, withNavigate, injectIntl, withConnect)(OrderUploadModal)
