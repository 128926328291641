import { COUNTRIES } from '@nv/react-commons/src/Constants'
import Config from 'configs'

export const INTERNAL_STATUS = {
  PENDING: 0,
  STAGING: 1,
  PENDING_PICKUP: 2,
  VAN_EN_ROUTE_TO_PICKUP: 3,
  EN_ROUTE_TO_SORTING_HUB: 4,
  ARRIVED_AT_SORTING_HUB: 5,
  ARRIVED_AT_ORIGIN_HUB: 6,
  ON_VEHICLE_FOR_DELIVERY: 7,
  COMPLETED: 8,
  PENDING_RESCHEDULE: 9,
  PICKUP_FAIL: 10,
  CANCELLED: 11,
  RETURNED_TO_SENDER: 12,
  ARRIVED_AT_DISTRIBUTION_POINT: 13,
  CROSS_BORDER_TRANSIT: 14,
  CUSTOMS_CLEARED: 15,
  CUSTOMS_HELD: 16,
  PENDING_PICKUP_AT_DISTRIBUTION_POINT: 17,
  ARRIVED_AT_ORIGIN_FACILITY: 18,
  PROCESSED_AT_ORIGIN_FACILITY: 19,
  LINEHAUL_SCHEDULED: 20,
  EXPORT_CLEARED: 21,
  LINEHAUL_DEPARTED: 22,
  LINEHAUL_ARRIVED: 23,
  HANDED_OVER_TO_LAST_MILE: 24,
  UNKNOWN: 25,
  ON_HOLD: 26,
  RTS_TRIGGERED: 27,
  EN_ROUTE_TO_ORIGIN_FACILITY: 28,
  RTS_AT_ORIGIN_FACILITY: 29,
  HANDED_OVER_TO_LINEHAUL: 30,
  FIRST_ATTEMPT_DELIVERY_FAIL: 31,
  PARCEL_LOST: 32,
  PARCEL_DAMAGED: 33,
  RETURNED_TO_XB_WAREHOUSE: 38,
  FULFILLMENT_REQUEST_SUBMITTED: 39,
  FULFILLMENT_PACKED: 40,
  IMPORT_STARTED: 43,
  EXPORT_STARTED: 44,
  PARCEL_EXCEPTION: 45,
  CUSTOMS_EXCEPTION: 46,
  PARCEL_DISPOSED: 47,
  ARRIVED_AT_TRANSIT_HUB: 48,
  IN_TRANSIT_TO_NEXT_SORTING_HUB: 49,
  ARRIVED_AT_DESTINATION_HUB: 50
}

export const STEPS = {
  SENDER: 0,
  COMPANY: 1,
  RECIPIENT: 2
}

export const INTERNAL_STATUS_LABEL_IMPLICIT = {
  [INTERNAL_STATUS.PENDING]: 'types.international_status.pending.with_type',
  [INTERNAL_STATUS.STAGING]: 'types.international_status.staging.with_type',
  [INTERNAL_STATUS.PENDING_PICKUP]: 'types.international_status.pending_pickup.with_type',
  [INTERNAL_STATUS.VAN_EN_ROUTE_TO_PICKUP]: 'types.international_status.van_en_route_to_pickup.with_type',
  [INTERNAL_STATUS.EN_ROUTE_TO_SORTING_HUB]: 'types.international_status.en_route_to_sorting_hub.with_type',
  [INTERNAL_STATUS.ARRIVED_AT_SORTING_HUB]: 'types.international_status.arrived_at_sorting_hub.with_type',
  [INTERNAL_STATUS.ARRIVED_AT_ORIGIN_HUB]: 'types.international_status.arrived_at_origin_hub.with_type',
  [INTERNAL_STATUS.ON_VEHICLE_FOR_DELIVERY]: 'types.international_status.on_vehicle_for_delivery.with_type',
  [INTERNAL_STATUS.COMPLETED]: 'types.international_status.completed.with_type',
  [INTERNAL_STATUS.PENDING_RESCHEDULE]: 'types.international_status.pending_reschedule.with_type',
  [INTERNAL_STATUS.PICKUP_FAIL]: 'types.international_status.pickup_fail.with_type',
  [INTERNAL_STATUS.CANCELLED]: 'types.international_status.cancelled.with_type',
  [INTERNAL_STATUS.RETURNED_TO_SENDER]: 'types.international_status.returned_to_sender.with_type',
  [INTERNAL_STATUS.ARRIVED_AT_DISTRIBUTION_POINT]: 'types.international_status.arrived_at_distribution_point.with_type',
  [INTERNAL_STATUS.CROSS_BORDER_TRANSIT]: 'types.granular_status.cross_border_in_transit.with_type',
  [INTERNAL_STATUS.CUSTOMS_CLEARED]: 'types.international_status.cross_border_customs_cleared.with_type',
  [INTERNAL_STATUS.CUSTOMS_HELD]: 'types.international_status.cross_border_customs_held.with_type',
  [INTERNAL_STATUS.PENDING_PICKUP_AT_DISTRIBUTION_POINT]:
    'types.international_status.pending_pickup_at_distribution_point.with_type',
  [INTERNAL_STATUS.ARRIVED_AT_ORIGIN_FACILITY]: 'types.international_status.arrived_at_origin_facility.with_type',
  [INTERNAL_STATUS.PROCESSED_AT_ORIGIN_FACILITY]: 'types.international_status.processed_at_origin_facility.with_type',
  [INTERNAL_STATUS.LINEHAUL_SCHEDULED]: 'types.international_status.linehaul_scheduled.with_type',
  [INTERNAL_STATUS.EXPORT_CLEARED]: 'types.international_status.export_cleared.with_type',
  [INTERNAL_STATUS.LINEHAUL_DEPARTED]: 'types.international_status.linehaul_departed.with_type',
  [INTERNAL_STATUS.LINEHAUL_ARRIVED]: 'types.international_status.linehaul_arrived.with_type',
  [INTERNAL_STATUS.HANDED_OVER_TO_LAST_MILE]: 'types.international_status.handed_over_to_last_mile.with_type',
  [INTERNAL_STATUS.ON_HOLD]: 'types.international_status.on_hold.with_type',
  [INTERNAL_STATUS.UNKNOWN]: 'types.international_status.unknown.with_type',
  [INTERNAL_STATUS.EN_ROUTE_TO_ORIGIN_FACILITY]: 'types.international_status.en_route_to_origin_facility.with_type',
  [INTERNAL_STATUS.RTS_AT_ORIGIN_FACILITY]: 'types.international_status.rts_at_origin_facility.with_type',
  [INTERNAL_STATUS.HANDED_OVER_TO_LINEHAUL]: 'types.international_status.handed_over_to_linehaul.with_type',
  [INTERNAL_STATUS.RTS_TRIGGERED]: 'types.international_status.rts_triggered.with_type',
  [INTERNAL_STATUS.FIRST_ATTEMPT_DELIVERY_FAIL]: 'types.international_status.first_attempt_delivery_fail.with_type',
  [INTERNAL_STATUS.RETURNED_TO_XB_WAREHOUSE]: 'types.international_status.returned_to_xb_warehouse.with_type',
  [INTERNAL_STATUS.FULFILLMENT_REQUEST_SUBMITTED]: 'types.international_status.fulfillment_request_submitted.with_type',
  [INTERNAL_STATUS.FULFILLMENT_PACKED]: 'types.international_status.fulfillment_packed_in_xb_warehouse.with_type',
  [INTERNAL_STATUS.PARCEL_EXCEPTION]: 'types.international_status.parcel_exception.with_type',
  [INTERNAL_STATUS.CUSTOMS_EXCEPTION]: 'types.international_status.customs_exception.with_type',
  [INTERNAL_STATUS.PARCEL_DISPOSED]: 'types.international_status.parcel_disposed.with_type',
  [INTERNAL_STATUS.PARCEL_LOST]: 'types.international_status.parcel_lost.with_type',
  [INTERNAL_STATUS.PARCEL_DAMAGED]: 'types.international_status.parcel_damaged.with_type',
  [INTERNAL_STATUS.IMPORT_STARTED]: 'types.international_status.import_started',
  [INTERNAL_STATUS.EXPORT_STARTED]: 'types.international_status.export_started',
  [INTERNAL_STATUS.ARRIVED_AT_TRANSIT_HUB]: 'types.international_status.arrived_at_transit_hub.with_type',
  [INTERNAL_STATUS.IN_TRANSIT_TO_NEXT_SORTING_HUB]:
    'types.international_status.in_transit_to_next_sorting_hub.with_type',
  [INTERNAL_STATUS.ARRIVED_AT_DESTINATION_HUB]: 'types.international_status.arrived_at_destination_hub.with_type'
}

export const DATE_TIME_FORMAT = {
  DATE_TIME: 'h:mm A',
  SHORT_DAY_TIME: 'DD MMM YYYY',
  SG_FULL_DAY_TIME: 'DD MMM YYYY, h:mm A',
  VN_FULL_DAY_TIME: 'DD MMM YYYY, H:mm'
}

export const COUNTRY_LABEL = {
  ID: 'Indonesia',
  MY: 'Malaysia',
  VN: 'Vietnam',
  TH: 'Thailand',
  SG: 'Singapore',
  PH: 'Philippines',
  CN: 'China',
  KR: 'South Korea',
  JP: 'Japan',
  TW: 'Taiwan',
  HK: 'Hong Kong'
}

export const MODAL_TYPE_VISIBLE = {
  ROLL_BACK: 'rollBackModalVisible',
  PRINT_AWB: 'awbModalVisible',
  POD_IMAGES: 'podImagesVisible'
}

export const errorCodes = {
  ENTITY_NOT_FOUND: 150002,
  NOT_FOUND: 150001
}

export const TRACKING_URL = {
  [COUNTRIES.SG]: `${Config.WEBSITE_URL}/en-sg/international/tracking`,
  [COUNTRIES.PH]: `${Config.WEBSITE_URL}/en-ph/international/tracking`,
  [COUNTRIES.MY]: `${Config.WEBSITE_URL}/en-my/international/tracking`,
  [COUNTRIES.TH]: `${Config.WEBSITE_URL}/th-th/international/tracking`,
  [COUNTRIES.ID]: `${Config.WEBSITE_URL}/id-id/international/tracking`,
  [COUNTRIES.VN]: `${Config.WEBSITE_URL}/vn-vn/international/tracking`,
  [COUNTRIES.MA]: `${Config.WEBSITE_URL}/my-mm/international/tracking`
}

export const DEBOUNCE_CLICK = 250

export const ITEM_TABLE_LIMIT = 100

export const TYPE = {
  UNKNOWN: 0,
  PARCEL: 1,
  BOX_B2B: 2,
  MMCC_PARCEL: 4,
  BOX_B2C: 5,
  B2B_BUNDLE: 6,
}

export const TRANSLATION_KEY_BY_TYPE = {
  [TYPE.UNKNOWN]: 'parcel',
  [TYPE.PARCEL]: 'parcel',
  [TYPE.BOX_B2B]: 'box_uppercase',
  [TYPE.BOX_B2C]: 'box_uppercase',
  [TYPE.MMCC_PARCEL]: 'parcel',
  [TYPE.B2B_BUNDLE]: 'b2b_bundle_capitalized',
}

export const VENDOR_STAGE = {
  FM: 'FM',
  LM: 'LM'
}
