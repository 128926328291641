/**
 *
 * Mapping
 *
 */

import {
  selectAllowedDeliveryTimeslots,
  selectIsEnabledTemperatureRanges,
  selectOcSettings,
  selectRecipientType
} from 'containers/Base/selectors'
import {
  selectIsCodSupported,
  selectIsNinjaPackOCSupported,
  selectIsCorporateAWBOCSupported,
  selectIsParcelOCSupported,
  selectIsB2BBundleSupported
} from 'containers/OrderCreate/selectors'
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import _ from 'lodash'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createSelector, createStructuredSelector } from 'reselect'
import { injectIntl } from 'react-intl'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { ApiHelper } from '@nv/react-commons/src/Services'
import { Checkbox, Dropdown, Menu, NVMonitor, NVModal, T } from '@nv/react-commons/src/Components'
import { SelectorUtils, StyleUtils } from '@nv/react-commons/src/Utils'
import { faPlus } from '@fa-pro-regular/faPlus'
import { faFile } from '@fa-pro-regular/faFile'
import { faTruck } from '@fa-pro-regular/faTruck'
import { faBox } from '@fa-pro-regular/faBox'
import { faEdit } from '@fa-pro-regular/faEdit'
import { faList } from '@fa-pro-regular/faList'

import { dashApi } from 'services/api'
import csvMapping from 'utils/csvMapping'
import { OC_SERVICE_TYPES, ROUTES } from '../Base/constants'
import { orderCreateCreators } from '../Base/redux'
import MappingNameModal from '../MappingNameModal'
import MappingTemplateModal from '../MappingTemplateModal'
import OrderUploadModal from '../OrderUploadModal'
import { selectMapping } from '../OrderUploadModal/selectors'
import { selectShipper } from '../Base/selectors'

import { Button } from 'components/Button'
import { Divider } from 'components/Divider'
import { MappingItem } from 'components/MappingItem'
import { MappingSlot } from 'components/MappingSlot'
import { OCHeader } from 'components/OCHeader'
import { Text } from 'components/Text'
import { Vspace } from 'components/Vspace'
import { Colors, Fonts } from 'themes'
import { withNavigate, withLocation, withParams } from '../RouterHOCs'
import { mixpanelTrackOrderCreateSettingsEvents } from '../../components/Mixpanel/helpers'
import { selectIsAllowedToUseAccountBalance } from '../OrderCreate/selectors'
import { RecipientType } from 'containers/OrderTypeModal/constants'
const { selector } = SelectorUtils
const { MAPPING_NEW } = ROUTES
const UPLOAD_MODAL = 'upload'
const LEFT_SIDER_PADDING = 32
const COMPULSORY_PADDING = 24
const BORDER_WIDTH = 1
const LEFT_SIDER_WIDTH = MappingItem.WIDTH + COMPULSORY_PADDING * 2 + BORDER_WIDTH * 4
const customErrors = {
  150003: 'mapping_name_exists'
}

const StyledMainWrapper = styled.div`
  overflow-y: auto;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex: 1;
`
const StyledLeftSider = styled.div`
  overflow-y: auto;
  flex: none;
  padding: 32px 0 32px ${LEFT_SIDER_PADDING}px;
  background-color: ${Colors.white};
  overflow: auto;
`
const StyledMainArea = styled.div`
  overflow-y: auto;
  flex: 1;
  padding: 32px 48px;
  ${props =>
    !props.editing &&
    css`
      margin-left: 80px;
    `};
  overflow: auto;
`
const StyledRightSider = styled(StyledLeftSider)`
  overflow-y: auto;
  width: 226px;
  padding: 32px;
`
const StyledTitle = styled(Text).attrs({ size: 18, type: 'bold' })`
  margin-bottom: 16px;
`
const StyledSubtitle = styled(Text).attrs({ size: 12, type: 'bold' })`
  margin-bottom: 12px;
`
const StyledLabel = styled(Text).attrs({
  type: 'italic',
  color: Colors.pinkishGrey,
  inline: true
})`
  margin-left: 8px;
`
const StyledTitleMainArea = styled.div`
  margin: 0 8px;
`
const StyledTitleWrapper = styled.div`
  width: ${LEFT_SIDER_WIDTH}px;
`
const StyledCompulsory = styled.div`
  width: ${LEFT_SIDER_WIDTH}px;
  margin: 16px ${LEFT_SIDER_PADDING}px 16px 0;
  padding: ${COMPULSORY_PADDING}px;
  border-radius: 3px;
  border: none;
  background-color: ${StyleUtils.rgba(Colors.emerald, 0.1)};
`
const StyledOptional = styled(StyledCompulsory)`
  background-color: ${Colors.offWhite};
`
const StyledHeadersGroupTitle = styled(Text).attrs({ size: 12, type: 'bold' })`
  margin: 0 0 16px;
`
const StyledColumnsArea = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`
const StyledButton = styled(Button).attrs({
  size: 'small'
})`
  && {
    margin: 8px 0;
    width: 100%;
    ${props =>
      props.type !== 'primary' &&
      css`
        ${Fonts.style.regular};
        text-align: left;
      `};
  }
`
const StyledMenuItem = styled(Menu.Item)`
  height: 40px;
  line-height: 2;
  padding: 8px 20px;
  font-size: 12px;
`
export const MappingTypes = {
  New: 'New',
  Edit: 'Edit',
  Preview: 'Preview',
  EditErrors: 'EditErrors'
}

export class Mapping extends React.Component {
  isTrackingNumberRequired = () => this.props.trackingType.toLowerCase() === 'prefixless'

  deriveFieldsConfig = (serviceType, identifiedHeader) => {
    const {
      isCodSupported,
      shipper,
      isAllowedToUseAccountBalance,
      isB2BBundleSupported,
      allowedDeliveryTimeslots,
      isEnabledTemperatureRanges,
      recipientType
    } = this.props

    const isTrackingNumberRequired = this.isTrackingNumberRequired()
    const fields = csvMapping.getOrderFields({
      serviceType,
      shipper,
      isTrackingNumberRequired,
      isCodSupported,
      isAllowedToUseAccountBalance,
      isB2BBundleSupported,
      allowedDeliveryTimeslots,
      isEnabledTemperatureRanges,
      recipientType
    })
    return csvMapping.identifyFieldsPosition(fields, identifiedHeader)
  }

  getServiceType = () => {
    const recipientType = this.props.recipientType
    return recipientType && recipientType === RecipientType.B2B ? 'B2B Bundle' : this.state.serviceType
  }

  getInitialFieldsConfig = () => {
    const recipientType = this.props.recipientType
    const serviceType =
      recipientType && recipientType === RecipientType.B2B ? 'B2B Bundle' : this.props.location?.state?.serviceType
    const identifiedHeader = this.props.location?.state?.identifiedHeader
    return this.deriveFieldsConfig(serviceType, identifiedHeader)
  }

  state = {
    name: null,
    serviceType: null,
    includeHeader: true,
    fieldsConfig: this.getInitialFieldsConfig(),
    ...this.props.location.state
  }

  static toIdentifiedHeader = (fieldsConfig, numColumns) => {
    const identifiedHeader = new Array(numColumns)
    _.forEach(fieldsConfig, ({ position }, key) => {
      if (position >= 0) {
        identifiedHeader[position] = key
      }
    })
    return identifiedHeader
  }

  isFromOrderProcess = () => {
    const { from } = this.state
    return _.startsWith(from, ROUTES.OC_PROCESS)
  }

  isFromRegularOC = () => {
    const { from } = this.state
    return _.startsWith(from, `${ROUTES.OC}/`)
  }

  isFromNinjaPackOC = () => {
    const { from } = this.state
    return _.startsWith(from, `${ROUTES.OC_PACKS}/`)
  }

  isFromCorpAwbOC = () => {
    const { from } = this.state
    return _.startsWith(from, `${ROUTES.ORDER_CORPORATE_MANUAL_AWB}/`)
  }

  isFromOC = () => {
    return this.isFromRegularOC() || this.isFromNinjaPackOC() || this.isFromCorpAwbOC() || this.isFromOrderProcess()
  }

  getReturnPath = () => {
    switch (this.props.type) {
      case MappingTypes.Edit:
        return '/edit'
      case MappingTypes.EditErrors:
        return '/edit-errors'
      default:
        return ''
    }
  }

  componentDidMount() {
    const {
      getMappingRequest,
      type,
      params: { id }
    } = this.props
    if (type !== MappingTypes.New) {
      if (id) {
        getMappingRequest(id)
      } else {
        this.goBack()
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { fetching, mapping, mappingError, columns } = this.props
    const prevColumns = prevProps.columns
    // Force update the config if the file columns changed
    const forceUpdate = prevColumns?.length !== columns?.length || prevColumns?.some((col, i) => col !== columns?.[i])
    if ((prevProps.fetching && !fetching) || forceUpdate) {
      if (!mappingError || forceUpdate) {
        const { name, type, includeHeader, data } = mapping
        // The identified headers should be bound by the uploaded file columns
        const identifiedHeaders = data.slice(0, columns.length)
        // TODO [teddy / lim]: fix this
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          name,
          serviceType: type,
          includeHeader,
          fieldsConfig: this.deriveFieldsConfig(type, identifiedHeaders)
        })
      }
    }
  }

  isEditing() {
    return this.props.type !== MappingTypes.Preview
  }

  putItem = (itemId, columnId, oldItemId) => {
    const { fieldsConfig } = this.state
    fieldsConfig[itemId] = {
      ...(fieldsConfig?.[itemId] ?? {}),
      position: columnId
    }

    if (_.has(fieldsConfig, oldItemId) && itemId !== oldItemId) {
      fieldsConfig[oldItemId] = {
        ...(fieldsConfig?.[oldItemId] ?? {}),
        position: -1
      }
    }

    this.setState({ fieldsConfig })
  }

  handleConfirm = () => {
    const {
      confirmMapping,
      params: { id }
    } = this.props
    if (this.isFromOC()) {
      confirmMapping(parseInt(id, 10))
    }
    this.goBack()
  }

  handleCheckboxChange = e => {
    this.setState({ includeHeader: e.target.checked })
  }

  handleSwitchTo = serviceType => {
    const { columns } = this.props
    const identifiedHeader = Mapping.toIdentifiedHeader(this.state.fieldsConfig, columns.length)
    this.setState({
      serviceType,
      fieldsConfig: this.deriveFieldsConfig(serviceType, identifiedHeader)
    })
  }

  handleSave = () => {
    const { trackAddNewTemplate, trackEditExistingTemplate } = mixpanelTrackOrderCreateSettingsEvents()
    switch (this.props.type) {
      case MappingTypes.New:
        trackAddNewTemplate()
        this.pushTo(MappingNameModal.PATH)
        break
      case MappingTypes.Edit:
      case MappingTypes.EditErrors:
        trackEditExistingTemplate()
        /* eslint-disable no-case-declarations */
        const {
          params: { id }
        } = this.props
        this.props.updateMappingRequest(id, this.getPayload())
        break
    }
  }

  handleSaveNew = () => {
    const { navigate, location } = this.props
    const { serviceType, includeHeader, fieldsConfig } = this.state
    navigate(MAPPING_NEW, {
      state: {
        ...location.state,
        serviceType,
        includeHeader,
        fieldsConfig
      },
      replace: true
    })
  }

  handleCreate = ({ name }) => {
    this.setState({ name })
    this.props.createMappingRequest({
      ...this.getPayload(),
      name
    })
  }

  handleRename = ({ name }) => {
    this.setState({ name })
  }

  handleDelete = () => {
    const {
      intl,
      deleteMappingRequest,
      params: { id }
    } = this.props
    NVModal.confirm({
      title: intl.formatMessage({ id: 'are_you_sure' }),
      content: intl.formatMessage({ id: 'delete_reminder' }),
      okText: intl.formatMessage({ id: 'yes' }),
      cancelText: intl.formatMessage({ id: 'no' }),
      onOk() {
        deleteMappingRequest(id)
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onCancel() {}
    })
  }

  pushTo = modal => {
    const { navigate, location } = this.props
    navigate(`${modal}`, { state: location.state })
  }

  getPayload = () => {
    const { name, includeHeader, fieldsConfig } = this.state
    const data = Mapping.toIdentifiedHeader(fieldsConfig, this.props.columns.length)
    return { name, type: this.getServiceType(), includeHeader, data }
  }

  getMainTitle = () => {
    const mapTitle = {
      [MappingTypes.Preview]: 'previewing_mapping',
      [MappingTypes.New]: 'new_mapping',
      [MappingTypes.Edit]: 'editing_mapping',
      [MappingTypes.EditErrors]: 'editing_mapping'
    }

    return mapTitle[this.props.type]
  }

  goBack = runOrderCreate => {
    const {
      navigate,
      location: { state }
    } = this.props
    if (state && state.from) {
      const { from, ...rest } = state
      navigate(from, { state: { ...rest, runOrderCreate } })
    } else {
      navigate(-1)
    }
  }

  goBackAndConfirm = update => id => {
    const { mappings, updated, setMappings } = this.props
    const newMappings = _.isArray(mappings) ? [...mappings] : []
    if (update) {
      const updatedIndex = _.findIndex(mappings, { id: updated.id })
      newMappings[updatedIndex] = updated
    }
    setMappings(newMappings)
    this.goBack(this.props.location?.state?.runOrderCreate)
    this.props.confirmMapping(id)
  }

  getSwitchToOptions = () => {
    const { serviceType } = this.state
    const { intl, isParcelOCSupported, isNinjaPackOCSupported, isCorporateAWBOCSupported } = this.props
    const isFromOC = this.isFromOC()
    const isFromRegularOC = this.isFromRegularOC()
    const isFromNinjaPackOC = this.isFromNinjaPackOC()
    const isFromCorpAwbOC = this.isFromCorpAwbOC()
    const typePrefix = 'types.mapping'
    const switchToDom = {
      type: OC_SERVICE_TYPES.PARCEL,
      icon: faTruck,
      label: 'switch_to_x',
      values: { x: intl.formatMessage({ id: `${typePrefix}.parcel` }) },
      action: () => this.handleSwitchTo(OC_SERVICE_TYPES.PARCEL)
    }
    const switchToNinjaPack = {
      type: OC_SERVICE_TYPES.NINJA_PACKS,
      icon: faBox,
      label: 'switch_to_x',
      values: { x: intl.formatMessage({ id: `${typePrefix}.ninja_pack` }) },
      action: () => this.handleSwitchTo(OC_SERVICE_TYPES.NINJA_PACKS)
    }
    const switchToCorpAwb = {
      type: OC_SERVICE_TYPES.CORPORATE_AWB,
      icon: faBox,
      label: 'switch_to_x',
      values: { x: intl.formatMessage({ id: `${typePrefix}.corp_awb` }) },
      action: () => this.handleSwitchTo(OC_SERVICE_TYPES.CORPORATE_AWB)
    }

    let switchToOpts = []
    if (isFromNinjaPackOC || isFromCorpAwbOC) {
      switchToOpts = []
    } else if (isFromRegularOC) {
      switchToOpts = _.compact([isParcelOCSupported && switchToDom])
    } else if (!isFromOC) {
      switchToOpts = _.compact([
        isParcelOCSupported && switchToDom,
        isNinjaPackOCSupported && switchToNinjaPack,
        isCorporateAWBOCSupported && switchToCorpAwb
      ])
    }
    _.remove(switchToOpts, ({ type }) => type === serviceType)
    return switchToOpts
  }

  getActions = () => {
    const confirm = {
      label: 'confirm_mapping',
      action: this.handleConfirm
    }
    const edit = {
      icon: faEdit,
      label: 'edit_mapping',
      action: () => this.pushTo('edit')
    }
    const save = {
      label: 'save_mapping',
      loading: this.props.updating,
      action: this.handleSave
    }
    const saveNew = {
      icon: faPlus,
      label: 'save_as_new_mapping',
      action: this.handleSaveNew
    }
    const changeFile = {
      icon: faFile,
      label: 'change_file',
      action: () => this.pushTo(UPLOAD_MODAL)
    }
    const changeMapping = {
      label: 'change_mapping_template',
      action: () => this.pushTo(MappingTemplateModal.PATH)
    }
    const renameMapping = {
      label: 'rename_mapping',
      action: () => this.pushTo(MappingNameModal.PATH)
    }
    const deleteMapping = {
      label: 'delete_mapping',
      action: this.handleDelete
    }
    const switchToOpts = this.getSwitchToOptions()

    const mapActions = {
      [MappingTypes.Preview]: [confirm, edit, changeFile, changeMapping],
      [MappingTypes.New]: [
        save,
        ...(switchToOpts.length > 1 ? [changeFile, ...switchToOpts] : [...switchToOpts, changeFile])
      ],
      [MappingTypes.Edit]: [save, saveNew, renameMapping, deleteMapping, changeMapping, changeFile],
      [MappingTypes.EditErrors]: [save, renameMapping, changeMapping, changeFile]
    }

    return mapActions[this.props.type]
  }

  renderUnmappedHeaders = isCompulsory => {
    const arrFieldConfigs = _.toPairs(this.state.fieldsConfig)

    const filteredFieldConfigs = arrFieldConfigs.filter(
      ([, { compulsory, position }]) => position < 0 && isCompulsory === !!compulsory
    )
    if (_.isEmpty(filteredFieldConfigs)) {
      return (
        <Text size={13} color={Colors.warmGrey}>
          <T id='empty_unmapped_headers' />
        </Text>
      )
    } else {
      return _.map(filteredFieldConfigs, ([id, { compulsory }]) => (
        <MappingItem key={id} id={id} compulsory={compulsory} put={this.putItem} editing />
      ))
    }
  }

  renderColumns = fieldsConfig => {
    const { columns } = this.props
    const header = Mapping.toIdentifiedHeader(fieldsConfig, columns.length)
    return columns.map((data, idx) => (
      <MappingSlot
        key={idx}
        id={idx}
        data={data}
        includeHeader={this.state.includeHeader}
        item={header[idx]}
        compulsory={fieldsConfig[header[idx]]?.compulsory}
        putItem={this.putItem}
        editing={this.isEditing()}
      />
    ))
  }

  renderActions = actions => {
    return (
      <Menu
        onClick={({ key }) => {
          actions[key].action()
        }}
      >
        {_.map(actions, ({ label, values }, key) => (
          <StyledMenuItem key={key}>
            <T id={label} values={values} />
          </StyledMenuItem>
        ))}
      </Menu>
    )
  }

  render() {
    const { fieldsConfig, name, includeHeader } = this.state
    const { type } = this.props
    const editing = this.isEditing()
    const [primary, secondary, ...rest] = this.getActions()
    const isFromOC = this.isFromOC()
    const isFromRegularOC = this.isFromRegularOC()
    const isFromNinjaPackOC = this.isFromNinjaPackOC()
    const isFromCorpAwbOC = this.isFromCorpAwbOC()
    /* eslint-disable react/jsx-handler-names */
    return (
      <>
        <NVMonitor
          monitor='createMapping'
          successIndicator='id'
          successMessage='mapping_created'
          customErrors={customErrors}
          onSuccess={this.goBackAndConfirm(false)}
        />
        <NVMonitor
          monitor='updateMapping'
          successIndicator='id'
          successMessage='mapping_updated'
          customErrors={customErrors}
          onSuccess={this.goBackAndConfirm(true)}
        />
        <NVMonitor monitor='deleteMapping' successMessage='mapping_deleted' onSuccess={this.goBack} />

        {/* top header */}
        <OCHeader title='file_mapping' onLeave={this.goBack} />

        {/* main content */}
        <StyledMainWrapper>
          {/* left content */}
          {editing && (
            <StyledLeftSider>
              <StyledTitleWrapper>
                <StyledTitle>
                  <T id='drag_headers_instruction' />
                </StyledTitle>
              </StyledTitleWrapper>
              <StyledCompulsory>
                <StyledHeadersGroupTitle color={Colors.emerald}>
                  <T id='compulsory_headers' />
                </StyledHeadersGroupTitle>
                {this.renderUnmappedHeaders(true)}
              </StyledCompulsory>
              <StyledOptional>
                <StyledHeadersGroupTitle color={Colors.apricot}>
                  <T id='optional_headers' />
                </StyledHeadersGroupTitle>
                {this.renderUnmappedHeaders(false)}
              </StyledOptional>
            </StyledLeftSider>
          )}

          {/* middle content */}
          <StyledMainArea editing={editing}>
            <StyledTitleMainArea>
              <StyledTitle>
                <T id={this.getMainTitle()} values={{ mapName: name }} />
                <StyledLabel>
                  (<T id={_.snakeCase(this.getServiceType() || '')} prefix='types.mapping' />)
                </StyledLabel>
              </StyledTitle>
              <StyledSubtitle color={Colors.pinkishGrey}>
                <T id='columns_from_file' allCaps />
              </StyledSubtitle>
            </StyledTitleMainArea>
            <StyledColumnsArea>{this.renderColumns(fieldsConfig)}</StyledColumnsArea>
          </StyledMainArea>

          {/* right content */}
          <StyledRightSider>
            <Checkbox isSecondary checked={includeHeader} onChange={this.handleCheckboxChange} disabled={!editing}>
              <Text inline size={13}>
                <T id='has_header_row' />
              </Text>
            </Checkbox>
            <Vspace height={8} />
            <Divider />
            <Vspace height={8} />
            <StyledButton
              data-analyticsid={primary.label}
              onClick={primary.action}
              type='primary'
              loading={primary.loading}
              disabled={editing && !!_.find(fieldsConfig, { compulsory: true, position: -1 })}
            >
              <T id={primary.label} values={primary.values} allCaps />
            </StyledButton>
            <StyledButton data-analyticsid={secondary.label} onClick={secondary.action} icon={secondary.icon}>
              <T id={secondary.label} values={secondary.values} />
            </StyledButton>
            <Vspace height={8} />
            {rest.length > 1 && (
              <>
                <Divider />
                <Vspace height={8} />
                <Dropdown trigger={['click']} overlay={this.renderActions(rest)}>
                  <StyledButton icon={faList} rightIcon='dropdown' data-analyticsid='selectMoreCSVMappingActions'>
                    <T id='more_actions' />
                  </StyledButton>
                </Dropdown>
              </>
            )}
          </StyledRightSider>

          {/* modal components */}
          <MappingNameModal
            title={type === MappingTypes.New ? 'give_a_name' : 'rename_mapping_template'}
            name={name}
            onSubmit={type === MappingTypes.New ? this.handleCreate : this.handleRename}
          />
          <MappingTemplateModal returnPath={this.getReturnPath()} />
          <OrderUploadModal
            isOC={isFromOC}
            isRegularOC={isFromRegularOC}
            isNinjaPackOC={isFromNinjaPackOC}
            isCorporateAWBOC={isFromCorpAwbOC}
            path={UPLOAD_MODAL}
            hideSwitchToManual
          />
        </StyledMainWrapper>
      </>
    )
  }
}

Mapping.propTypes = {
  navigate: PropTypes.func,
  intl: PropTypes.object,
  location: PropTypes.object,
  columns: PropTypes.array,
  fetching: PropTypes.bool,
  isNinjaPackOCSupported: PropTypes.bool,
  isCorporateAWBOCSupported: PropTypes.bool,
  isParcelOCSupported: PropTypes.bool,
  mapping: PropTypes.object,
  mappingError: PropTypes.object,
  mappings: PropTypes.array,
  params: PropTypes.object,
  shipper: PropTypes.object.isRequired,
  trackingType: PropTypes.string,
  updated: PropTypes.object,
  updating: PropTypes.bool,
  confirmMapping: PropTypes.func.isRequired,
  createMappingRequest: PropTypes.func.isRequired,
  deleteMappingRequest: PropTypes.func.isRequired,
  getMappingRequest: PropTypes.func.isRequired,
  setMappings: PropTypes.func.isRequired,
  updateMappingRequest: PropTypes.func.isRequired,
  isCodSupported: PropTypes.bool,
  isAllowedToUseAccountBalance: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(MappingTypes)),
  allowedDeliveryTimeslots: PropTypes.array,
  isEnabledTemperatureRanges: PropTypes.bool,
  recipientType: PropTypes.string
}

Mapping.defaultProps = {
  trackingType: '',
  isEnabledTemperatureRanges: false
}

const mapStateToProps = createStructuredSelector({
  columns: selectMapping(),
  fetching: selector('entity', 'mapping', 'loading')(),
  isNinjaPackOCSupported: selectIsNinjaPackOCSupported(),
  isCorporateAWBOCSupported: selectIsCorporateAWBOCSupported(),
  isParcelOCSupported: selectIsParcelOCSupported(),
  isAllowedToUseAccountBalance: selectIsAllowedToUseAccountBalance(),
  mappings: selector('entity', 'mappings', 'data')(),
  mapping: selector('entity', 'mapping', 'data')(),
  mappingError: selector('entity', 'mapping', 'error')(),
  shipper: selectShipper(),
  trackingType: createSelector(selectOcSettings(), ocSettings => ocSettings?.trackingType),
  updating: selector('entity', 'updateMapping', 'loading')(),
  updated: selector('entity', 'updateMapping', 'data')(),
  isCodSupported: selectIsCodSupported(),
  isB2BBundleSupported: selectIsB2BBundleSupported(),
  allowedDeliveryTimeslots: selectAllowedDeliveryTimeslots(),
  isEnabledTemperatureRanges: selectIsEnabledTemperatureRanges(),
  recipientType: selectRecipientType()
})

function mapDispatchToProps(dispatch) {
  return {
    confirmMapping: id => dispatch(orderCreateCreators.set('csvMapping', { id })),
    createMappingRequest: data => dispatch(ApiHelper.creators.request('createMapping', dashApi.createMapping, [data])),
    deleteMappingRequest: id => dispatch(ApiHelper.creators.request('deleteMapping', dashApi.deleteMapping, [id])),
    getMappingRequest: id => dispatch(ApiHelper.creators.request('mapping', dashApi.getMapping, [id])),
    setMappings: mappings => dispatch(ApiHelper.creators.set('mappings', mappings)),
    updateMappingRequest: (id, data) =>
      dispatch(ApiHelper.creators.request('updateMapping', dashApi.updateMapping, [id, data]))
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  injectIntl,
  withNavigate,
  withLocation,
  withParams,
  withConnect,
  DragDropContext(HTML5Backend)
)(Mapping)
